/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: #101522;
  padding: 0 0 25px 0;
  color: #eee;
  font-size: 14px;
}
#footer .footer-top {
  background: #040919;
  padding: 60px 0 30px 0;
}

#footer .copyright {
  text-align: center;
  padding-top: 30px;
}
#footer .credits {
  text-align: center;
  font-size: 13px;
  color: #ddd;
}

.vf-footer{
  color: #b28c5d;
}