/*--------------------------------------------------------------
# Buy Tickets Section
--------------------------------------------------------------*/
#buy {
    padding: 60px 0;
  }
  #buy .card {
    border: none;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 10px 25px 0 rgba(6, 12, 34, 0.1);
  }
  #buy .card:hover {
    box-shadow: 0 10px 35px 0 rgba(6, 12, 34, 0.2);
  }
  #buy .card hr {
    margin: 25px 0;
  }
  #buy .card .card-title {
    margin: 10px 0;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: bold;
  }
  #buy .card .card-price {
    font-size: 48px;
    margin: 0;
  }
  #buy .card ul li {
    margin-bottom: 20px;
  }
  #buy .card .text-muted {
    opacity: 0.7;
  }
  #buy .card .btn {
    font-size: 15px;
    border-radius: 50px;
    padding: 10px 40px;
    transition: all 0.2s;
    background-color: #f82249;
    border: 0;
    color: #fff;
  }
  #buy .card .btn:hover {
    background-color: #e0072f;
  }