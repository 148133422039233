#subscribe {
    padding: 60px;
    /* background: url(../img/subscribe-bg.jpg) center center no-repeat; */
    background-size: cover;
    overflow: hidden;
    position: relative;
}

#subscribe:before {
content: "";
background: rgba(6, 12, 34, 0.6);
position: inherit;
bottom: 0;
top: 0;
left: 0;
right: 0;
}

@media (min-width: 1024px) {
    #subscribe {
        background-attachment: fixed;
    }
}

#subscribe .section-header h2, #subscribe p {
color: #fff;
}

#subscribe input {
background: #fff;
color: #060c22;
border: 0;
outline: none;
margin: 0;
padding: 9px 20px;
border-radius: 50px;
font-size: 14px;
}

@media (min-width: 767px) {
    #subscribe input {
        min-width: 400px;
    }
}



.d-flex{
    display: flex;
    justify-content: center;
}

.te-gustaria{
    font-size: 15px !important;
}