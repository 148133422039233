.button-icon-song{
    position: fixed;
    bottom: 12px;
    left: 12px;
    height: 50px;
    width: 50px;

    border-radius: 50%;
    border: none;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    animation: songAnimation 3s ease 1s infinite alternate-reverse none;
}

@keyframes songAnimation {
	0% {
		animation-timing-function: ease-out;
		transform: scale(1);
		transform-origin: center center;
	}

	10% {
		animation-timing-function: ease-in;
		transform: scale(0.91);
	}

	17% {
		animation-timing-function: ease-out;
		transform: scale(0.98);
	}

	33% {
		animation-timing-function: ease-in;
		transform: scale(0.87);
	}

	45% {
		animation-timing-function: ease-out;
		transform: scale(1);
	}
}

.song-icon{
    height: 30px;
    width: 30px;
}



/* @media (max-width: 767px) { /*si quiero desaparecer el boton en el celular
    .button-icon-song{
        display: none;
    }
    .song-icon{
        display: none;
    }
} */


@media (max-width: 967px) { 
    .song-icon{
        width: 25px;
        height: 25px;
    }
    

    .button-icon-song{
        height: 40px;
        width: 40px;
    }


}

@media (max-width: 467px) { 
    .song-icon{
        width: 15px;
        height: 15px;
    }
    

    .button-icon-song{
        height: 30px;
        width: 30px;
    }


}