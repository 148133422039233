/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  background: #fff;
  color: #2f3138;
  font-family: "Open Sans", sans-serif;
}

body::-webkit-scrollbar {
  display: none;
}


a {
  color: #b88d85;
  text-decoration: none !important;
  transition: 0.5s;
}

a:hover, a:active, a:focus {
  color: #d1d1d1 !important;
  outline: none;
  text-decoration: none;
}

p {
  padding: 0;
  margin: 0 0 30px 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Cormorant Infant', serif;
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
  color: #1f1e1e;
}

.main-page {
  margin-top: 70px;
}

/* Prelaoder */
#preloader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  overflow: visible;
  /*background: #fff url("../img/preloader.svg") no-repeat center center;*/
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #e0aca2;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;
}
.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}
.back-to-top:hover {
  background: #663333;
  color: #fff;
}
.back-to-top.active {
  visibility: visible;
  opacity: 1;
}
  /*animation icons*/
.sink-on-hover {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.sink-on-hover:hover, .sink-on-hover:focus, .sink-on-hover:active {
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
}

/*animation buttons*/
.shrink-on-hover-button {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.shrink-on-hover-button:hover, .shrink-on-hover-button:focus, .shrink-on-hover-button:active {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}
