.button-icon-BtoTop{
    position: fixed;
    bottom: 12px;
    right: 12px;
    height: 50px;
    width: 50px;

    border-radius: 50%;
    border: none;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.scroll-icon{
    height: 30px;
    width: 30px;
}

/* @media (max-width: 767px) { /*si quiero desaparecer el boton en el celular
    .button-icon{
        display: none;
    }
    .scroll-icon{
        display: none;
    }
} */

@media (max-width: 967px) { 
    .scroll-icon{
        width: 25px;
        height: 25px;
    }
    

    .button-icon-BtoTop{
        height: 40px;
        width: 40px;
    }


}

@media (max-width: 467px) { 
    .scroll-icon{
        width: 15px;
        height: 15px;
    }
    

    .button-icon-BtoTop{
        height: 30px;
        width: 30px;
    }


}