#wbutton button {
    border: 0;
    padding: 9px 25px;
    cursor: pointer;
    background: #858a9f;
    color: #fff;
    transition: all 0.3s ease;
    outline: none;
    font-size: 14px;
    border-radius: 50px;
}
    
#wbutton button:hover {
    background: #414248;
}

@media (max-width: 460px) {
    #wbutton button {
        margin-top: 10px;
    }
}