/* Sections Header
--------------------------------*/
.section-header {
    margin-bottom: 40px;
    position: relative;
    /* padding-bottom: 20px; */
  }
  /* .section-header::before {
    content: "";
    position: absolute;
    display: block;
    width: 60px;
    height: 5px;
    background: #e0aca2;
    bottom: 0;
    left: calc(50% - 25px);
  } */
  .section-header h2 {
    font-size: 36px;
    /* text-transform: uppercase; */
    text-align: center;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .section-header p {
    text-align: center;
    padding: 0;
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: #9195a2;
  }
  
  .section-with-bg {
    background-color: #f6f7fd;
  }
  
  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  #header {
    height: 90px;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    transition: all 0.5s;
    z-index: 997;
  }
  #header.header-scrolled, #header.header-inner {
    background: rgba(6, 12, 34, 0.98);
    height: 70px;
  }
  #header #logo h1 {
    font-size: 36px;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    letter-spacing: 3px;
    text-transform: uppercase;
  }
  #header #logo h1 span {
    color: #e0aca2;
  }
  #header #logo h1 a, #header #logo h1 a:hover {
    color: #fff;
  }
  #header #logo img {
    padding: 0;
    margin: 0;
    max-height: 100px;
    max-width: 100px;
  }
  @media (max-width: 992px) {
    #header #logo img {
      max-height: 100px;
      max-width: 100px;
    }
  }

  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /**
  * Desktop Navigation 
  */
  .navbar {
    padding: 0;
  }
  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  .navbar li {
    position: relative;
  }
  .navbar > ul > li {
    white-space: nowrap;
    padding: 10px 0 10px 12px;
  }
  .navbar a, .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: rgba(202, 206, 221, 0.8);
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    font-size: 14px;
    white-space: nowrap;
    transition: 0.3s;
    position: relative;
    padding: 6px 4px;
  }
  .navbar a i, .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }
  .navbar > ul > li > a:before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -6px;
    left: 0;
    background-color: #b28c5d;
    visibility: hidden;
    transition: all 0.3s ease-in-out 0s;
  }
  .navbar a:hover:before, .navbar li:hover > a:before, .navbar .active:before {
    visibility: visible;
    width: 100%;
  }
  .navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
    color: #fff;
  }
  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 12px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
  }
  .navbar .dropdown ul li {
    min-width: 200px;
  }
  .navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 14px;
    text-transform: none;
    color: #060c22;
  }
  .navbar .dropdown ul a i {
    font-size: 12px;
  }
  .navbar .dropdown ul a:hover, .navbar .dropdown ul .active:hover, .navbar .dropdown ul li:hover > a {
    color: #e0aca2;
  }
  .navbar .dropdown:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }
  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }
  .navbar .dropdown .dropdown:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
  @media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
      left: -90%;
    }
    .navbar .dropdown .dropdown:hover > ul {
      left: -100%;
    }
  }
  
  /**
  * Mobile Navigation 
  */
  .mobile-nav-toggle {
    color: #fff;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
  }
  
  @media (max-width: 991px) {
    .mobile-nav-toggle {
      display: block;
    }
  
    .navbar.has-burger ul li:not(.burger) a {
        display: none;
    }
    .navbar li.burger {
        width: 50px;
        height: 50px;
        background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAKElEQVRIiWNgGAXDHjAisf/TwmwmKhs6CgYhGE1Fo4ByMJqKRsEQAADWCQMKYvEFtQAAAABJRU5ErkJggg==");
        background-position: center;
        background-repeat: no-repeat;
        filter: invert(1);
        cursor: pointer;
        position: fixed;
        top: 20px;
        right: 30px;
        z-index: 99;
        transform: scale(1.4);
    }
    .navbar.shown ul {
      display: block !important;
      position: fixed;
      top:0;
      bottom:0;
      right: 0;
      background: #fff;
      padding: 50px 20px 0;
      box-shadow: 0 1px 35px rgba(0,0,0,.3);
    }
    .navbar.shown ul li {
      display: block !important;
    }
    .navbar.shown ul li.close {
        display: inline-block;
        width: 30px;
        height: 30px;
        background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNTAiIGhlaWdodD0iNTAiCnZpZXdCb3g9IjAgMCA1MCA1MCIKc3R5bGU9IiBmaWxsOiMwMDAwMDA7Ij48cGF0aCBkPSJNIDcuNzA3MDMxMiA2LjI5Mjk2ODggTCA2LjI5Mjk2ODggNy43MDcwMzEyIEwgMjMuNTg1OTM4IDI1IEwgNi4yOTI5Njg4IDQyLjI5Mjk2OSBMIDcuNzA3MDMxMiA0My43MDcwMzEgTCAyNSAyNi40MTQwNjIgTCA0Mi4yOTI5NjkgNDMuNzA3MDMxIEwgNDMuNzA3MDMxIDQyLjI5Mjk2OSBMIDI2LjQxNDA2MiAyNSBMIDQzLjcwNzAzMSA3LjcwNzAzMTIgTCA0Mi4yOTI5NjkgNi4yOTI5Njg4IEwgMjUgMjMuNTg1OTM4IEwgNy43MDcwMzEyIDYuMjkyOTY4OCB6Ij48L3BhdGg+PC9zdmc+') 50% 50% no-repeat;
        background-size: 100%;
        background-position: center;
        margin-bottom: 15px;
        cursor: pointer;
    }
    .navbar.shown ul li a {
      color: #000;
        padding: 5px 25px;
    }
    .navbar.shown ul li:hover, .navbar.shown .active {
        color: rgba(0,0,0,.7) !important;
    }
    .navbar.shown ul li a:hover {
      color: rgba(0,0,0,.7);
        padding: 5px 25px;
    }
  }
  .navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    transition: 0.3s;
    z-index: 999;
  }
  .navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  .navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
  }
  .navbar-mobile > ul > li {
    padding: 0;
  }
  .navbar-mobile a:hover:before, .navbar-mobile li:hover > a:before, .navbar-mobile .active:before {
    visibility: hidden;
  }
  .navbar-mobile a, .navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #060c22;
  }
  .navbar-mobile a:hover, .navbar-mobile .active, .navbar-mobile li:hover > a {
    color: #e0aca2;
  }
  .navbar-mobile .getstarted, .navbar-mobile .getstarted:focus {
    margin: 15px;
  }
  .navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  }
  .navbar-mobile .dropdown ul li {
    min-width: 200px;
  }
  .navbar-mobile .dropdown ul a {
    padding: 10px 20px;
  }
  .navbar-mobile .dropdown ul a i {
    font-size: 12px;
  }
  .navbar-mobile .dropdown ul a:hover, .navbar-mobile .dropdown ul .active:hover, .navbar-mobile .dropdown ul li:hover > a {
    color: #e0aca2;
  }
  .navbar-mobile .dropdown > .dropdown-active {
    display: block;
  }