/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 100vh;
  /*background: url('../../assets/hero-bg.jpg') top center;*/
  background-position: top center;
  background-size: cover;
  overflow: hidden;
  position: relative;
}
@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
    background-position:  center;
  }
}
#hero:before {
  content: "";
  background: rgba(6, 12, 34, 0.3);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
#hero .hero-container {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 90px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0 15px;
}
@media (max-width: 991px) {
  #hero .hero-container {
    top: 70px;
  }
}
#hero h1 {
  color: #fff;
  font-family: 'Parisienne', cursive;
  font-size: 90px;
  /* font-weight: 600; */
  margin-bottom: 1px;
  /* text-transform: uppercase; */
}

@media (max-width: 991px) {
  #hero h1 {
    font-size: 73px;
  }
}

@media (max-width: 528px) {
  #hero h1 {
    font-size: 50px;
  }
}
#hero p {
  font-family: 'Didact Gothic';
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #ebebeb;
  /* font-weight: 700; */
  font-size: 20px;
}
@media (max-width: 991px) {
  #hero p {
    font-size: 17px;
  }
}

@media (max-width: 510px) {
  #hero p {
    font-size: 15px;
    font-weight: 600;
  }
}
  
  
 