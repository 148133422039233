/*social*/
#SocialMedia ul{
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 40px;
    
}

#SocialMedia li{
    margin: 0 10px 0 0;
}


#SocialMedia li svg{
    width:30px;
    height:30px;
    filter: invert(100%);
}